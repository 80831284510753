import React, { useContext, createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { gql, useLazyQuery } from '@apollo/client';
import refreshToken from "../helpers/refreshToken";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(authContext);
};

const setAccessToken = token => token && localStorage.setItem('accessToken', token)

const setRefreshToken = token => token && localStorage.setItem('refreshToken', token)

const getAccessToken = () => localStorage.getItem('accessToken')

const getRefreshToken = () => localStorage.getItem('refreshToken')

const isTokenExpired = token => {
	try {
		const decoded = jwt_decode(token);
		if (decoded.exp < Date.now() / 1000) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		localStorage.removeItem("refreshToken")
		localStorage.removeItem("accessToken")
		return false;
	}
};

const loggedIn = () => {
	const token = getAccessToken();
	const refreshToken = getRefreshToken();
	if ((!!token && !isTokenExpired(token)) || (!!refreshToken && !isTokenExpired(refreshToken))) {
		return true
	}
	return false
};

const storeTokens = tokens => {
	setAccessToken(tokens.access)
	setRefreshToken(tokens.refresh)
}

const LOGIN_GQL = gql`
  mutation Login($email: String!, $password: String!) {
    loginBo(data: {email: $email, password: $password}) {
      access
      refresh
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      firstname
      lastname
			role
      id			
    }
  }
`;

// Provider hook that creates auth object and handles state
function useProvideAuth() {

	const [getCurrentUser, { loading, data, error }] = useLazyQuery(GET_CURRENT_USER);

	useEffect(() => {
		const token = getRefreshToken()
		if (!loggedIn() && token) {
			getCurrentUser()
		}
		// eslint-disable-next-line
	}, [])

	const [user, setUser] = useState(null);
	useEffect(() => {
		if (loggedIn()) {
			getCurrentUser()
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (data?.currentUser?.role !== "") {
			setUser(data?.currentUser)
		}
	}, [data])

	if (error) {
		if (error.message.includes('missing jwt key')) {
			refreshToken()
		}
	}

	const logout = () => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		window.location.href = '/login';
	}

	// Return the user object and auth methods
	return {
		loadingAuth: loading,
		LOGIN_GQL,
		user,
		storeTokens,
		loggedIn,
		getAccessToken,
		getRefreshToken,
		getCurrentUser,
		logout
	};
}